import { useRouter } from 'next/router'
import { useContext, useEffect, useState } from 'react'

import { buildActionAnalyticsAttributes } from '@mondough/analytics-v2'
import { Text, Title } from '@mondough/monzo-ui'

import { ActionButton, Loading, PlainLayout } from '../components'
import { useFormatMessage } from '../hooks'
import { ReferralContext, TabContext } from '../providers'
import logoutIcon from '../public/images/icons/logout.svg'
import {
  Tab,
  getAuthValue,
  getCDNSrc,
  getOwedAmount,
  getUserProfileSrc,
  userLogout,
} from '../utils'
import styles from './index.module.scss'

const getFirstThreeParticipants = (tab: Tab) => {
  const userId = getAuthValue('userId')
  const otherPeople = tab.participants.filter(
    (person) => person.user_id !== userId,
  )
  const firstThreeParticipants = otherPeople.slice(0, 3)
  return firstThreeParticipants.map((p) => ({
    name: p.name,
    src: p.type === 'monzo' ? getUserProfileSrc(p.user_id) : undefined,
  }))
}

export default function Homepage() {
  const [loading, setLoading] = useState(true)
  const [activeTabs, setActiveTabs] = useState<Tab[]>([])
  const [settledTabs, setSettledTabs] = useState<Tab[]>([])
  const { tabs: userTabs } = useContext(TabContext)
  const { setReferral } = useContext(ReferralContext)

  const router = useRouter()
  const formatMessage = useFormatMessage()

  const getSubtitle = (tab: Tab) => {
    if (tab.status !== 'open') {
      return tab.closed_at
        ? formatMessage('home.page.archived.subtitle.with-date', {
            date: new Date(tab.closed_at).toLocaleDateString(),
          })
        : formatMessage('home.page.archived.subtitle.without-date')
    }
    const isUserOwed = getOwedAmount(tab) > 0
    const formattedAmount = Math.abs(getOwedAmount(tab) / 100).toFixed(2)
    return isUserOwed
      ? formatMessage('home.page.active.subtitle.owed', {
          amount: formattedAmount,
        })
      : formatMessage('home.page.active.subtitle.owes', {
          amount: formattedAmount,
        })
  }

  useEffect(() => {
    const onLoad = async () => {
      setReferral(null)
      setLoading(true)
      setActiveTabs(
        userTabs ? userTabs.filter((tab) => tab.status === 'open') : [],
      )
      setSettledTabs(
        userTabs ? userTabs.filter((tab) => tab.status !== 'open') : [],
      )
      if (userTabs) setLoading(false)
    }
    void onLoad()
    return () => {
      setLoading(false)
      setActiveTabs([])
      setSettledTabs([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userTabs])

  if (loading) {
    return <Loading />
  }

  return (
    <PlainLayout
      displayCardLayout={false}
      displayBreadcrumb={false}
      className={styles.wrapper}
    >
      <Title
        id="homepage-title"
        is="h1"
        size="medium"
        className={styles['wrapper-title']}
      >
        {formatMessage('home.page.title')}
      </Title>
      {activeTabs.length ? (
        activeTabs.map((tab, i) => (
          <ActionButton
            id={`tab-${tab.id}`}
            key={tab.id}
            title={tab.name}
            subtitle={getSubtitle(tab)}
            connectedDown={i !== activeTabs.length - 1}
            connectedUp={i !== 0}
            participants={getFirstThreeParticipants(tab)}
            onClick={async () => {
              await router.push('/tab/[tabId]', `/tab/${tab.id}`)
            }}
            {...buildActionAnalyticsAttributes('tab', {
              tabId: tab.id,
            })}
          />
        ))
      ) : (
        <Text bold className={styles['no-active-tabs']}>
          {formatMessage('home.page.no-active-tabs')}
        </Text>
      )}
      {settledTabs.length ? (
        <Title
          is="h2"
          size="medium"
          className={styles['wrapper-settled-title']}
        >
          {formatMessage('home.page.settled-tabs')}
        </Title>
      ) : null}
      {settledTabs.map((tab, i) => (
        <ActionButton
          id={`settled-tab-${tab.id}`}
          key={tab.id}
          title={tab.name}
          subtitle={getSubtitle(tab)}
          connectedDown={i !== settledTabs.length - 1}
          connectedUp={i !== 0}
          isDisabled
          participants={getFirstThreeParticipants(tab)}
        />
      ))}
      <ActionButton
        id="log-out"
        title={formatMessage('home.page.logout.title')}
        subtitle={formatMessage('home.page.logout.subtitle')}
        onClick={async () => {
          await userLogout(true)
        }}
        assetLeft={{
          iconSrc: getCDNSrc(logoutIcon),
        }}
        mobileOnly
        {...buildActionAnalyticsAttributes('logout')}
      />
    </PlainLayout>
  )
}

Homepage.analytics = { name: 'homepage' }
